<button
  [ngClass]="{
    'button-abled': !disabled && !isCancel,
    'button-disabled': disabled,
    'button-cancel': isCancel,
  }"
  [style.height]="height"
  [style.width]="width"
  [style.padding]="padding"
  [disabled]="disabled"
  (click)="onClick()"
>
  {{ text }}
</button>
