import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";

@Component({
  selector: "input-select",
  templateUrl: "./input-select.component.html",
  styleUrl: "./input-select.component.scss",
})
export class InputSelectComponent {
  @Input() placeholder: string = "placeholder";
  @Input() form?: FormGroup;
  @Input() options: any[] = [];
  @Input() controlName: string = "";
  @Input() label: string = "";

}
