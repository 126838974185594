import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-textfield",
  templateUrl: "./textfield.component.html",
  styleUrl: "./textfield.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextfieldComponent,
      multi: true,
    },
  ],
})
export class TextfieldComponent implements ControlValueAccessor {
  @Input()
  public type: string = "text";
  @Input()
  public label: string = "label";
  @Input()
  public msgError: string = "Error";
  @Input()
  public width: string = "100%";
  @Input()
  public height: string = "56px";
  @Input()
  public radius: string = "16px";
  @Input()
  public readOnly: boolean = false;
  @Input()
  public showIcon: boolean = true;
  @Input()
  public placeHolder: string = "Introducir...";
  @Input()
  public iconPath: string = "";
  @Input()
  public errorMessage: string = "¡Error!";
  @Output()
  public fieldFocused = new EventEmitter();

  //Variables
  public inputValue: string = "";
  public isInvalid: boolean = false;

  public visible: boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  openModal() {
    this.fieldFocused.emit();
  }

  viewPassword() {
    this.visible = !this.visible;
  }

  //ControlValueAccesor
  writeValue(inputValue: any): void {
    this.inputValue = inputValue;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
