import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
})
export class ButtonComponent {
  @Input()
  public text: string = "Botón";
  @Input()
  public width: string = "100%";
  @Input()
  public height: string = "56px";
  @Input()
  public padding: string = "10px";
  @Input()
  public disabled: boolean = false;
  @Input()
  public isCancel: boolean = false;

  @Output()
  public buttonClicked = new EventEmitter<void>();

  onClick() {
    if (!this.disabled) {
      this.buttonClicked.emit();
    }
  }
}
