import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TelephoneFieldComponent } from "./telephone-field/telephone-field.component";
import { FormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  declarations: [TelephoneFieldComponent],
  imports: [CommonModule, FormsModule, MatSnackBarModule],
  exports: [TelephoneFieldComponent],
})
export class MoleculesModule {}
