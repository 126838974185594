<div class="textfield-container">
  <label class="label-text">{{ label }}</label>
  <div class="input-container">
    <div class="icon">
      <button class="select-country" (click)="onSelectCountryCodeButtonTap()">
        <img [src]="getFlagUrl()" alt="" />
        <span>{{ countryCode?.dial_code }}</span>
        <i class="pi pi-chevron-down"></i>
      </button>
    </div>
    <input type="tel"
      class="text-field-normal"
      [style.width]="width"
      [placeholder]="placeHolder"
      [readonly]="readOnly"
      [style.border-radius]="radius"
      [style.height]="height"
      [(ngModel)]="inputValue"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()" />
  </div>
</div>