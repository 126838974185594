<div class="modal-container">
  <div class="modal-content">
    <div class="title-container">
      <p>La contraseña debe cumplir con las siguientes características:</p>
    </div>
    <div class="text-container">
      <ul>
        <li>Incluir letras, números y caracteres <br />especiales.</li>
        <li>Combinar letras mayúsculas y minúsculas.</li>
        <li>
          En longitud debe ser igual o mayor a 8 <br />
          caracteres.
        </li>
        <li>No debe tener espacios en blanco.</li>
      </ul>
    </div>
    <div class="button">
      <app-button
        [text]="'Continuar'"
        (buttonClicked)="closeModal()"
      ></app-button>
    </div>
  </div>
</div>
