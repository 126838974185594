import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SelectCountryCodeDialogComponent } from "../../atoms/select-country-code/select-country-code-dialog.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CountryCode } from "../../../../domain/models/country-code";
import { AppResourcesConfig } from "../../../../core/resources/app-resources.config";

@Component({
  selector: "app-telephone-field",
  templateUrl: "./telephone-field.component.html",
  styleUrl: "./telephone-field.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TelephoneFieldComponent,
      multi: true,
    },
  ],
})
export class TelephoneFieldComponent implements ControlValueAccessor {
  @Input()
  public label: string = "label";
  @Input()
  public width: string = "100%";
  @Input()
  public height: string = "56px";
  @Input()
  public radius: string = "16px";
  @Input()
  public readOnly: boolean = false;
  @Input()
  public placeHolder: string = "Introducir...";

  public inputValue: string = "";

  @Output()
  countryEvent = new EventEmitter<CountryCode>();
  public countryCode: CountryCode | undefined;

  constructor(private router: Router, public dialog: MatDialog) {
    this.countryCode = {
      name: "Colombia",
      code: "CO",
      dial_code: "+57",
    };
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  onSelectCountryCodeButtonTap() {
    this.openSelectCountryCodeDialog();
  }

  openSelectCountryCodeDialog(): void {
    const dialogRef = this.dialog.open(SelectCountryCodeDialogComponent);

    dialogRef
      .afterClosed()
      .subscribe((countryCode: CountryCode | undefined) => {
        if (countryCode == undefined) return;
        this.countryCode = countryCode;
        this.countryEvent.emit(countryCode);
      });
  }

  //ControlValueAccesor
  writeValue(inputValue: any): void {
    this.inputValue = inputValue;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  getFlagUrl(): string {
    let countryCode = (this.countryCode?.code ?? "default").toLowerCase();
    return AppResourcesConfig.countryCodeFlag(countryCode);
  }
}
