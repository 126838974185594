import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextfieldComponent } from "./textfield/textfield.component";
import { ButtonComponent } from "./button/button.component";
import { TermsConditionsFieldComponent } from "./terms-conditions-field/terms-conditions-field.components";
import { SelectCountryCodeDialogComponent } from "./select-country-code/select-country-code-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { ConfirmCheckComponent } from "./confirm-check/confirm-check.components";
import { FacebookButtonComponent } from "./facebook-button/facebook-button.component";
import { InputSelectComponent } from "./input-select/input-select.component";
import { DropdownModule } from "primeng/dropdown";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputRadioComponent } from "./input-radio/input-radio.component";
import { InputMoneyComponent } from "./input-money/input-money.component";
import { InputNumberModule } from "primeng/inputnumber";

@NgModule({
  declarations: [
    TextfieldComponent,
    ButtonComponent,
    TermsConditionsFieldComponent,
    SelectCountryCodeDialogComponent,
    ConfirmCheckComponent,
    FacebookButtonComponent,
    InputSelectComponent,
    InputRadioComponent,
    InputMoneyComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    DropdownModule,
    RadioButtonModule,
    InputNumberModule,
  ],
  exports: [
    TextfieldComponent,
    ButtonComponent,
    TermsConditionsFieldComponent,
    SelectCountryCodeDialogComponent,
    ConfirmCheckComponent,
    FacebookButtonComponent,
    InputSelectComponent,
    InputRadioComponent,
    InputMoneyComponent,
  ],
})
export class AtomsModule {}
