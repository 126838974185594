<div class="terms-conditions-container">
  <input class="input-checkbox"
    type="checkbox"
    id="agree_terms"
    [(ngModel)]="inputValue"
    (ngModelChange)="onChange($event)"
    required />
  <label class="input-label"
    for="agree_terms">
    <p>Acepta <span>Términos y condiciones y Tratamiento de datos.</span></p>
  </label>
</div>