<div class="terms-conditions-container">
  <input
    [(ngModel)]="inputValue"
    (ngModelChange)="onChange($event)"
    class="input-checkbox"
    type="checkbox"
    id="agree"
    required
  />
  <label class="input-label" for="agree">
    {{ checkText }}
  </label>
</div>
