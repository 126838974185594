<div class="modal-container">
  <div class="modal-content">
    <div class="title text-center">
      <span class="bold-w700-h24"> Agregar Beneficiario </span>
    </div>
    <div class="container-body">
      <div class="d-flex justify-content-between align-items-center">
        <span>¿No encuentras al beneficiario?</span>
        <button
          class="new-item-button-1 flex flex-row mr-12 items-center semibold-w600-h20 mx-1 py-1 px-4"
        >
          <img class="" src="assets/icons/plus_icon.svg" alt="" />
          Crear beneficiario
        </button>
      </div>

      <form [formGroup]="form" class="mt-4">
        <div class="field">
          <input-select
            controlName="accountType"
            [form]="form"
            [options]="identificationTypes"
            placeholder="Seleccionar"
            [label]="'Beneficiario'"
          ></input-select>
          <div
            class="error-message"
            *ngIf="
              form.get('accountType')?.invalid && form.get('accountType')?.dirty
            "
          >
            <img
              *ngIf="!form.get('accountType')?.hasError('pattern')"
              src="\assets\icons\error.svg"
              alt=""
            />
            Campo requerido
          </div>
        </div>
        <div class="field mt-3 container-number">
          <app-input-money
            [form]="form"
            placeholder="Ingresar"
            [controlName]="'amount'"
            [label]="'Distribución del pago'"
            [code]="'%'"
          ></app-input-money>
          <div
            class="error-message"
            *ngIf="form.get('amount')?.invalid && form.get('amount')?.dirty"
          >
            <img src="\assets\icons\error.svg" alt="" />
            <!-- {{ msgValidateId }} -->
            Campo requerido
          </div>
        </div>
        <div class="field mt-3">
          <input-select
            controlName="account"
            [form]="form"
            [options]="identificationTypes"
            placeholder="Seleccionar"
            [label]="'Cuenta de pago'"
          ></input-select>
          <div
            class="error-message"
            *ngIf="form.get('account')?.invalid && form.get('account')?.dirty"
          >
            <img
              *ngIf="!form.get('account')?.hasError('account')"
              src="\assets\icons\error.svg"
              alt=""
            />
            Campo requerido
          </div>
        </div>
      </form>
    </div>
    <div class="button mt-4 mb-2 row justify-content-around content-buttons">
      <app-button
        [text]="'cancelar'"
        (buttonClicked)="closeModal()"
        [isCancel]="true"
        class="col-6"
      ></app-button>
      <app-button
        [text]="buttonText"
        class="col-6"
        (click)="saveUser()"
        [disabled]="!form.valid"
      ></app-button>
    </div>
  </div>
</div>
