import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppResourcesService } from "@core/resources/app-resources.service";

@Component({
  selector: "app-metamap-modal",
  templateUrl: "./metamap-modal.component.html",
  styleUrl: "./metamap-modal.component.scss",
})
export class MetamapModalComponent {
  @Input() modalIcon: string = AppResourcesService.icons.in_progress;
  @Input() tittleText: string = "Solicitud en proceso";
  @Input() buttonLeftText: string = "Ir a Soporte";
  @Input() buttonRigthText: string = "Volver a intentarlo";
  @Input() showButtons: boolean = false;
  @Output() buttonLeftEvent = new EventEmitter();
  @Output() ButtonRightEvent = new EventEmitter();
  @Input() twoButtons: boolean = true;
  @Input() widthButton: String = ""
  @Input() hrWidth: String = ""
  @Input() bodyText: boolean = true
  @Input() contentText: string =
    "Enviaremos a tu correo registrado una solicitud para finalizar el proceso de registro.  <strong>Tienes 48 horas para dar respuesta.</strong>";


    buttonLeftAction() {
      console.log('buttonLeftAction')
      this.buttonLeftEvent.emit();
    }
  
    ButtonRightAction() {
      console.log('ButtonRightAction')
      this.ButtonRightEvent.emit();
    }



  }
