import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-input-radio",
  templateUrl: "./input-radio.component.html",
  styleUrl: "./input-radio.component.scss",
})
export class InputRadioComponent {
  @Input()
  public radioLabel: string;
  @Input()
  public radioValue: string;
  @Input()
  public name: string;
  radioModel: string;
  public inputValue: string = "";
  @Input() form?: FormGroup;
  @Input() controlName: string = "";
}
