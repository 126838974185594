<div class="container-principal">
  <div class="container-modal">
    <img [src]="modalIcon" alt="" class="mb-3" />
    <span class="text-title">{{ tittleText }}</span>
    <hr class="my-2" [style.width]="hrWidth" *ngIf="bodyText" />
    <p *ngIf="bodyText" class="text-content" [innerHTML]="contentText"></p>
  </div>
  <div *ngIf="!bodyText" class="hrTwo" [style.width]="hrWidth"></div>
  <div
    *ngIf="showButtons"
    class="d-flex gap-3 boxButtons"
    [style.width]="widthButton"
  >
    <button (click)="buttonLeftAction()" *ngIf="twoButtons" class="button-left">
      {{ buttonLeftText }}
    </button>
    <button (click)="ButtonRightAction()" class="button-right">
      {{ buttonRigthText }}
    </button>
  </div>
</div>
