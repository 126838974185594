<div class="modal-container">
  <div class="modal-content">
    <div class="text">
      <p>
        La razón social debe ser la misma que aparece registrada en Cámara de
        Comercio
      </p>
    </div>
    <div class="button mt-4 mb-2">
      <app-button
        [text]="'Continuar'"
        (buttonClicked)="closeModal()"
      ></app-button>
    </div>
  </div>
</div>
