import { Component } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-terms-conditions-field",
  templateUrl: "./terms-conditions-field.component.html",
  styleUrl: "./terms-conditions-field.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TermsConditionsFieldComponent,
      multi: true,
    },
  ],
})
export class TermsConditionsFieldComponent implements ControlValueAccessor {
  public inputValue: string = "";

  onChange: any = () => {};
  onTouch: any = () => {};

  //ControlValueAccesor
  writeValue(inputValue: any): void {
    this.inputValue = inputValue;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
