import { Component } from "@angular/core";
import { PasswordModalService } from "./services/password-modal.service";

@Component({
  selector: "app-password-modal",
  templateUrl: "./password-modal.component.html",
  styleUrl: "./password-modal.component.scss",
})
export class PasswordModalComponent {
  constructor(private modalService: PasswordModalService) {}

  closeModal() {
    this.modalService.$modal.emit(false);
  }
}
