import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AtomsModule } from "../atoms/atoms.module";
import { PasswordModalComponent } from "./password-modal/password-modal.component";
import { SocialReasonModalComponent } from "./social-reason-modal/social-reason-modal.component";
import { MetamapModalComponent } from "./metamap-modal/metamap-modal.component";
import { CustomModalComponent } from "./custom-modal/custom-modal.component";
import { BenficiarieModalComponent } from "./benficiarie-modal/benficiarie-modal.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    SocialReasonModalComponent,
    PasswordModalComponent,
    MetamapModalComponent,
    CustomModalComponent,
    BenficiarieModalComponent,
  ],
  exports: [
    SocialReasonModalComponent,
    PasswordModalComponent,
    MetamapModalComponent,
    CustomModalComponent,
    BenficiarieModalComponent,
  ],
  imports: [CommonModule, AtomsModule, ReactiveFormsModule],
})
export class OrganismsModule {}
