@if (form) {
<ng-container [formGroup]="form">
  <div class="flex align-items-center">
    <p-radioButton
      [value]="radioValue"
      [name]="name"
      [formControlName]="controlName"
    ></p-radioButton>
    <label for="ingredient1" class="ml-2 regular-w400-h16 text-white">{{
      radioLabel
    }}</label>
  </div>
</ng-container>
}
