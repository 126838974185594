import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DialogModule } from "primeng/dialog";
import { SidebarModule } from "primeng/sidebar";
import { PanelMenuModule } from "primeng/panelmenu";
import { MenuModule } from "primeng/menu";
import { AtomsModule } from "./atoms/atoms.module";
import { MoleculesModule } from "./molecules/molecules.module";
import { OrganismsModule } from "./organisms/organisms.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DialogModule,
    SidebarModule,
    PanelMenuModule,
    MenuModule,
    //Atoms
    AtomsModule,
    //Molecules
    MoleculesModule,
    //Organisms
    OrganismsModule,
  ],
  exports: [AtomsModule, MoleculesModule, OrganismsModule],
})
export class UtilsModule {}
