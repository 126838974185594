<div class="textfield-container" [ngClass]="{}">
  <label class="label-text">{{ label }}</label>
  <div class="input-container">
    <!-- TODO: change icon color depending on focus -->
    @if(showIcon){

    <div class="icon">
      <img [src]="iconPath" alt="TextFieldIcon" aria-hidden="true" />
    </div>
    }
    <input
      [ngClass]="{
        'is-invalid': isInvalid,
        'p-left': showIcon
      }"
      [type]="visible ? 'text' : type"
      type="text"
      class="text-field-normal"
      [style.width]="width"
      [placeholder]="placeHolder"
      [readonly]="readOnly"
      [style.border-radius]="radius"
      [style.height]="height"
      [(ngModel)]="inputValue"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()"
      (focus)="openModal()"
    />
    <div *ngIf="type == 'password'" (click)="viewPassword()">
      <img
        class="eyeicon"
        [src]="visible ? 'assets/icons/eye_closed.svg' : 'assets/icons/eye.svg'"
        alt="eye"
      />
    </div>
  </div>
</div>
