import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PasswordModalService {
  constructor() {}

  $modal = new EventEmitter<any>();
}
