@if (form) {
<ng-container [formGroup]="form">
  <label class="label-text mb-1">{{ label }}</label>
  <div class="container-input">
    <p-inputNumber placeholder="0,00" [formControlName]="controlName">
    </p-inputNumber>
    <span class="content-code">{{ code }}</span>
  </div>
</ng-container>
}
