import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InputNumberModule } from "primeng/inputnumber";

@Component({
  selector: "app-input-money",
  templateUrl: "./input-money.component.html",
  styleUrl: "./input-money.component.scss",
})
export class InputMoneyComponent {
  @Input() placeholder: string = "placeholder";
  @Input() form?: FormGroup;
  @Input() controlName: string = "";
  @Input() readOnly: boolean = false;
  @Input() code: string = "COP";
  @Input() label: string = "";
}
