import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BeneficiarieModalService } from "./services/beneficiarie-modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-benficiarie-modal",
  templateUrl: "./benficiarie-modal.component.html",
  styleUrl: "./benficiarie-modal.component.scss",
})
export class BenficiarieModalComponent implements OnInit {
  @Input() public buttonText: string = "Agregar";
  @Output() store: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  identificationTypes: any[];
  constructor(
    private modalService: BeneficiarieModalService,
    private _formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this._formBuilder.group({
      amount: ["", Validators.required],
      accountType: ["", Validators.required],
      account: [""],
    });
    this.identificationTypes = [
      {
        name: "cuenta treebu - 9424 3242 8276 6784",
        value: "cuenta treebu - 9424 3242 8276 6784",
      },
      {
        name: "cuenta otro - 9424 3242 8276 6784",
        value: "cuenta otro - 9424 3242 8276 6784",
      },
    ];
  }
  closeModal() {
    this.modalService.$modal.emit(false);
  }

  private getControl(name: string) {
    return this.form.controls[name];
  }

  getBeneficiarie() {
    return {
      name: "Martin Vasco",
      amount: this.getControl("amount").value,
      acount: this.getControl("accountType").value,
    };
  }
  saveUser() {
    this.store.emit(this.getBeneficiarie());
  }
}
