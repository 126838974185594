@if (form) {
<ng-container [formGroup]="form">
  <label class="label-text">{{ label }}</label>
  <p-dropdown
    styleClass="type-event-select"
    [options]="options"
    [formControlName]="controlName"
    optionLabel="name"
    [placeholder]="placeholder"
    optionValue="value"
  >
  </p-dropdown>
</ng-container>
}
