import { Component, EventEmitter, Input, Output, input } from "@angular/core";
import { CustomModalService } from "./services/custom-modal.service";

@Component({
  selector: "app-custom-modal",
  templateUrl: "./custom-modal.component.html",
  styleUrl: "./custom-modal.component.scss",
})
export class CustomModalComponent {
  @Input() public modalText: string = "";
  @Input() public buttonText: string = "Continuar";
  @Input() public isConfirm: boolean = false;
  @Input() public iconModal: string = "";
  @Input() public type: string = "confirm";

  @Output()
  public onClick = new EventEmitter<void>();
  constructor(private modalService: CustomModalService) {}

  closeModal() {
    if (this.type == "confirm") {
      this.modalService.$modal.emit(false);
    }
    if (this.type == "success") {
      this.modalService.$successModal.emit(false);
    }
  }

  onClicked() {
    this.onClick.emit();
    if (this.type == "confirm") {
      this.modalService.$modal.emit(false);
    }
    if (this.type == "success") {
      this.modalService.$successModal.emit(false);
    }
  }
}
