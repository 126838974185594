<button
  class="button-abled"
  [style.height]="height"
  [style.width]="width"
  [style.padding]="padding"
  [disabled]="disabled"
  (click)="onClick()"
>
<img src="assets/icons/facebook.svg" alt="">
  {{ text }}
</button>
