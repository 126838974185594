@if(type == 'confirm'){

<div class="modal-container">
  <div class="modal-content">
    <div class="bold-w700-h24">
      <p>{{ modalText }}</p>
    </div>
    <div class="button mt-4 mb-2 row justify-content-around">
      <app-button
        *ngIf="isConfirm"
        [text]="'Cancelar'"
        (buttonClicked)="closeModal()"
        [isCancel]="true"
        class="col-6"
      ></app-button>
      <app-button
        [text]="buttonText"
        (buttonClicked)="onClicked()"
        class="col-6"
      ></app-button>
    </div>
  </div>
</div>
} @if(type =='success'){
<div class="modal-container">
  <div class="modal-content success">
    <img [src]="iconModal" alt="" class="icon-modal" />
    <div class="bold-w700-h32 mt-4">
      <p>{{ modalText }}</p>
    </div>
    <div class="border-modal"></div>
    <div class="button mt-4 mb-2 w-50">
      <app-button
        [text]="buttonText"
        (buttonClicked)="onClicked()"
      ></app-button>
    </div>
  </div>
</div>
}
