import { Component } from "@angular/core";
import { SocialReasonModalService } from "./services/social-reason-modal.service";

@Component({
  selector: "app-social-reason-modal",
  templateUrl: "./social-reason-modal.component.html",
  styleUrl: "./social-reason-modal.component.scss",
})
export class SocialReasonModalComponent {
  constructor(private modalService: SocialReasonModalService) {}

  closeModal() {
    this.modalService.$modal.emit(false);
  }
}
